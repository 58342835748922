import React, { Component } from "react";

// Conf
import conf from "./_core/_conf";

// Core
import Loader from "./_core/components/Loader";
import Media from "./_core/components/Media";
import Site from "./_core/components/Site";
import Burger from "./_core/components/Burger";
import Content from "./_core/components/Content";
import Image from "./_core/components/Image";
import SiteModifier from "./_core/components/SiteModifier";
import ViewportProgress from "./_core/components/ViewportProgress";
import FadeIn from "./_core/components/FadeIn";
import Link from "./_core/components/Link";
import Gallery from "./_core/components/Gallery";
import Transition from "./_core/components/Transition";
import Toggle from "./_core/components/Toggle";
import Html from "./_core/components/Html";
import Icon from "./_core/components/Icon";
import Map from "./_core/components/Map";
import EqualHeight from "./_core/components/EqualHeight";
import Form from "./_core/components/Form";
import Filter from "./_core/components/Filter";
import Favicon from "react-favicon";

import { Entry, Data, Asset } from "./_core/models";
import { withStore } from "./_core/utils";
import { Helmet } from "react-helmet";
import { withRouter } from "react-router-dom";

import mapTint from "./json/mapTint";

import * as Breakpoints from "./_core/utils/Breakpoints";

// Layout
import Layout from "./templates/_layout";

// Project
import * as Project from "./components";

let TOUCH = !!("ontouchstart" in window);

let palettes = [
  // Blue
  {
    bg: "#D4E3E6",
    contrast: "#074D65",
    accent: "#D6AB4D",
  },
  // Green
  {
    bg: "#D6E1CB",
    contrast: "#14613E",
    accent: "#DF5043",
  },
  // Pink
  {
    bg: "#EFCDCD",
    contrast: "#8E3450",
    accent: "#374c98",
  },
  // Purple
  {
    bg: "#D6D2E9",
    contrast: "#6e5167",
    accent: "#609387",
  },
  // Brown
  {
    bg: "#D8CFC9",
    contrast: "#7a624f",
    accent: "#d33449",
  },
];

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = { hover: 0, progress: 0, palette: palettes.shuffle()[0] };
  }

  componentDidMount = () => {
    let _this = this;
  };

  componentWillMount = () => {
    const _this = this;
  };

  activate = (id) => {
    if (this.state.hover != id) {
      this.setState({ hover: id });
    }
  };

  render() {
    // Load the settings to get the manifest (JSON data to power the site)
    let settings = conf.get();
    let _this = this;

    // this.state.palette.bg
    return (
      <Site>
        <Favicon
          url={[
            "/ui/img/favicon-blue.png",
            "/ui/img/favicon-green.png",
            "/ui/img/favicon-pink.png",
            "/ui/img/favicon-purple.png",
            "/ui/img/favicon-brown.png",
          ]}
        />

        <FadeIn duration={0.2}>
          <div
            className="Site-bg"
            style={{
              backgroundColor: "#DFF0E5", // this.state.palette.bg,
            }}
          >
            <div className="Site-halftone" />
            <div className="Site-splash" />

            <style type="text/css">
              {`
            
            .Site-search-form input::placeholder,
            .Site-search-form .Icon{
              color: ${this.state.palette.contrast}!important;
             
            }

      

            .Site-browse{
               background: ${this.state.palette.accent};
               color: ${this.state.palette.accent};

            }



            .Site-browse .Icon{
               
               color: ${this.state.palette.bg};
            }

            @media (orientation: portrait){
              .Site-browse{
                background: ${this.state.palette.bg};
              }

              .Site-browse .Icon{
                color: ${this.state.palette.accent};
              }
            }

            .Site-nav .List a.is-inactive{
              //color: ${this.state.palette.accent};
            }
            .Site-nav .List a.is-active{
              color: ${this.state.palette.accent};
            }

            .Acme-50 a {
              color: #F79B2E !important;
            }
          `}
            </style>

            <Loader
              manifest={[...settings.MANIFEST]}
              progress={() => {
                return (
                  <div
                    className="Loader"
                    style={{ background: this.state.palette.contrast }}
                  />
                );
              }}
              wait={900}
              loaded={({ entries }) => {
                return (
                  <Data require={["entries", "navigation"]}>
                    {({ entries, structures, filters, FILTERING, DETAILS }) => {
                      let HOME = entries.find((e) => e.uri == "/");

                      return (
                        <FadeIn duration={0.8} delay={0.7}>
                          <SiteModifier
                            modifier="loadComplete"
                            auto
                            delay={0}
                          />
                          <SiteModifier modifier="ready" auto delay={0} />
                          <SiteModifier
                            modifier="showMap"
                            auto
                            forceDelay
                            delay={1.2}
                          />

                          {/* Filtering modifier (set in Project.Router) */}
                          {(() => {
                            if (FILTERING || DETAILS) {
                              return (
                                <SiteModifier
                                  modifier="filtering"
                                  auto
                                  key={Math.random()}
                                  delay={0}
                                />
                              );
                            } else {
                              return (
                                <SiteModifier
                                  demodifier="filtering"
                                  auto
                                  key={Math.random()}
                                  delay={0}
                                />
                              );
                            }
                          })()}

                          {/* Details modifier (set in Project.Router) */}
                          {(() => {
                            if (DETAILS) {
                              return (
                                <SiteModifier
                                  modifier="details"
                                  auto
                                  key={Math.random()}
                                  delay={0}
                                />
                              );
                            } else {
                              return (
                                <SiteModifier
                                  demodifier="details"
                                  auto
                                  key={Math.random()}
                                  delay={0}
                                />
                              );
                            }
                          })()}

                          <div className="Site-head" id="head">
                            <div className="Site-logo">
                              <Link to={"/"}>{settings.SITENAME}</Link>
                            </div>

                            <div className="Site-nav">
                              <Data require={["navigation"]}>
                                {({ navigation }) => {
                                  return (
                                    <ul className="List List--nav">
                                      {navigation.map((item, ix) => {
                                        return (
                                          <li
                                            key={"nav_" + ix}
                                            className={
                                              item.slug === "acme-50" && "Acme-50" 
                                              || item.slug === "acme-propeller-factory-campaign" && "propeller"
                                              || item.slug === "acme-propeller-factory" && "propeller"
                                              || item.slug === "acme-propeller-factory-1" && "propeller"
                                            }
                                          >
                                            <Link
                                              navlink
                                              autotarget
                                              matchAgainst={
                                                window.location.pathname
                                              }
                                              matchUri={item.uri}
                                              to={item.uri}
                                            >
                                              {item.title}
                                            </Link>
                                          </li>
                                        );
                                      })}
                                    </ul>
                                  );
                                }}
                              </Data>
                            </div>
                          </div>

                          {/* Search */}
                          <div className="Site-search">
                            <div
                              className="Site-search-form"
                              style={{ background: this.state.palette.bg }}
                            >
                              <Form
                                modifier="icon"
                                onSubmit={(data) => {
                                  let uri;
                                  if (data.query) {
                                    uri =
                                      window.location.pathname +
                                      "#/find/" +
                                      encodeURIComponent(data.query) +
                                      "/";
                                  } else {
                                    uri = window.location.pathname;
                                  }

                                  this.props.history.push(uri);
                                }}
                                fields={[
                                  {
                                    type: "text",
                                    name: "query",
                                    placeholder: "Search our site & spaces...",
                                  },
                                ]}
                                submit={<Icon type={"search"} glyph />}
                              ></Form>
                            </div>
                          </div>

                          {/* Results */}
                          <div
                            className="Site-results"
                            style={{ background: this.state.palette.bg }}
                          >
                            <Data require={["FILTER"]}>
                              {({ SEARCH, FILTER, SEGMENTS, entries }) => {
                                return (
                                  <div className="Results">
                                    <div
                                      className="Results-head"
                                      style={{
                                        background: this.state.palette.bg,
                                      }}
                                    >
                                      <div className="Results-back">
                                        <Link to={window.location.pathname}>
                                          <Icon glyph type={"left"} />
                                        </Link>
                                      </div>
                                      <Toggle
                                        tag={"div"}
                                        autoclose
                                        className="Results-dropdown"
                                      >
                                        <div className="Results-dropdown-head">
                                          <Content>
                                            <h2>
                                              {SEARCH
                                                ? `Search: "${SEARCH}"`
                                                : FILTER.title}
                                            </h2>
                                          </Content>
                                          <div className="Results-dropdown-arrow">
                                            <span>
                                              <Icon glyph type="down" />
                                            </span>
                                          </div>
                                        </div>
                                        <div className="Results-dropdown-body">
                                          <Data require={["filters"]}>
                                            {({ navigation, filters }) => {
                                              return (
                                                <ul className="List List--dropdown">
                                                  <li
                                                    onClick={(ev) =>
                                                      ev.stopPropagation()
                                                    }
                                                    key={"filter_search"}
                                                  >
                                                    <Form
                                                      modifier="icon"
                                                      onSubmit={(data) => {
                                                        let uri;
                                                        if (data.query) {
                                                          uri =
                                                            window.location
                                                              .pathname +
                                                            "#/find/" +
                                                            encodeURIComponent(
                                                              data.query
                                                            ) +
                                                            "/";
                                                        } else {
                                                          uri =
                                                            window.location
                                                              .pathname;
                                                        }

                                                        this.props.history.push(
                                                          uri
                                                        );
                                                      }}
                                                      fields={[
                                                        {
                                                          type: "text",
                                                          name: "query",
                                                          placeholder:
                                                            "Search...",
                                                        },
                                                      ]}
                                                      submit={
                                                        <Icon
                                                          type={"search"}
                                                          glyph
                                                        />
                                                      }
                                                    ></Form>
                                                  </li>
                                                  {filters.map((item, ix) => (
                                                    <li key={"filter_" + ix}>
                                                      <Link
                                                        navlink
                                                        autotarget
                                                        matchUri={
                                                          "#/filter/" +
                                                          item.slug +
                                                          "/"
                                                        }
                                                        to={
                                                          "#/filter/" +
                                                          item.slug +
                                                          "/"
                                                        }
                                                      >
                                                        {item.title}
                                                      </Link>
                                                    </li>
                                                  ))}
                                                </ul>
                                              );
                                            }}
                                          </Data>
                                        </div>
                                      </Toggle>
                                    </div>
                                    <div className="Results-body">
                                      <Filter
                                        search={
                                          !SEARCH
                                            ? null
                                            : {
                                                value: SEARCH,
                                                fields: [
                                                  "title",
                                                  "text",
                                                  "location",
                                                  "address",
                                                  "metaKeywords",
                                                  "metaTitle",
                                                  "metaDescription",
                                                  "uri",
                                                ],
                                              }
                                        }
                                        filters={{
                                          type: [
                                            "locations",
                                            "pages",
                                            "listing",
                                          ],
                                        }}
                                        data={
                                          !FILTER
                                            ? entries
                                            : FILTER.related.map((id) =>
                                                entries.find((e) => e.id == id)
                                              )
                                        }
                                        render={(item) => {
                                          if (item.type == "locations") {
                                            return (
                                              <div
                                                className={
                                                  "Item Item--listing " +
                                                  (this.state.hover == item.id
                                                    ? "is-active"
                                                    : "is-inactive")
                                                }
                                                key={item.id}
                                                onMouseLeave={
                                                  TOUCH
                                                    ? null
                                                    : () =>
                                                        this.setState({
                                                          hover: 0,
                                                        })
                                                }
                                                onMouseOver={
                                                  TOUCH
                                                    ? null
                                                    : () =>
                                                        this.activate(item.id)
                                                }
                                              >
                                                <div className="Item-content">
                                                  <div className="Item-head">
                                                    <Content>
                                                      <h1>{item.title}</h1>
                                                      <h2>{item.location}</h2>
                                                      <Html
                                                        html={item.address}
                                                      />
                                                    </Content>
                                                  </div>
                                                  <div className="Item-foot">
                                                    <Link
                                                      to={
                                                        window.location
                                                          .pathname +
                                                        `#/${SEGMENTS[1]}/${SEGMENTS[2]}` +
                                                        "/details/" +
                                                        item.slug +
                                                        "/"
                                                      }
                                                    >
                                                      Details
                                                    </Link>
                                                  </div>
                                                </div>
                                                <div
                                                  className="Item-media"
                                                  key={item.thumbnail}
                                                >
                                                  <Link
                                                    to={
                                                      window.location.pathname +
                                                      `#/${SEGMENTS[1]}/${SEGMENTS[2]}` +
                                                      "/details/" +
                                                      item.slug +
                                                      "/"
                                                    }
                                                  >
                                                    <Asset
                                                      id={item.thumbnail}
                                                      render={(asset) => {
                                                        return (
                                                          <Image
                                                            src={
                                                              asset.sizes.small
                                                            }
                                                            fluid={true}
                                                            lazy={true}
                                                          />
                                                        );
                                                      }}
                                                    />
                                                  </Link>
                                                </div>

                                                <div className="Item-body"></div>
                                              </div>
                                            );
                                          } else {
                                            let hasThumb =
                                              item.thumbnail &&
                                              item.thumbnail.length;

                                            return (
                                              <Link
                                                to={item.uri}
                                                className={
                                                  "Item Item--block Item--" +
                                                  (hasThumb
                                                    ? "hasImage"
                                                    : "noImage")
                                                }
                                              >
                                                <div className="Item-content">
                                                  {(() => {
                                                    if (
                                                      item.thumbnail &&
                                                      item.thumbnail.length
                                                    ) {
                                                      return (
                                                        <div className="Item-media">
                                                          <Media
                                                            id={item.thumbnail}
                                                            fluid={false}
                                                            ratio={6 / 4}
                                                            lazy={true}
                                                          />
                                                        </div>
                                                      );
                                                    }
                                                  })()}
                                                  <div className="Item-head">
                                                    <Content>
                                                      <h1>{item.title}</h1>
                                                      <p>
                                                        {item.metaDescription}
                                                      </p>
                                                    </Content>
                                                  </div>
                                                  <div className="Item-foot">
                                                    View
                                                  </div>
                                                </div>
                                              </Link>
                                            );
                                          }
                                        }}
                                      />
                                    </div>
                                  </div>
                                );
                              }}
                            </Data>
                          </div>

                          {/* Details */}
                          <div className="Site-detail">
                            <Data require={["DETAIL"]}>
                              {({ DETAIL, FILTER, SEARCH, SEGMENTS }) => {
                                return (
                                  <div className="Results">
                                    <div className="Results-head">
                                      <div className="Results-close">
                                        <Link
                                          to={
                                            window.location.pathname +
                                            (FILTER || SEARCH
                                              ? `#/${SEGMENTS[1]}/${SEGMENTS[2]}/`
                                              : "")
                                          }
                                        >
                                          <Icon glyph type={"cross"} />
                                        </Link>
                                      </div>
                                      <Content>
                                        <h2>
                                          {DETAIL.title}, {DETAIL.location}
                                        </h2>
                                      </Content>
                                    </div>
                                    <div className="Results-body">
                                      <Project.Blocks
                                        blocks={[
                                          {
                                            type: "gallery",
                                            images: DETAIL.images.map(
                                              (image) => ({ image: [image] })
                                            ),
                                            style: "carousel",
                                            size: "half",
                                          },
                                          {
                                            type: "text",
                                            text: DETAIL.text,
                                            style: "default",
                                            size: "half",
                                          },
                                          {
                                            type: "text",
                                            text:
                                              `<h4>Address</h4>${DETAIL.address}` +
                                              DETAIL.details
                                                .map(
                                                  (detail) =>
                                                    `<h4>${detail.heading}</h4>${detail.text}`
                                                )
                                                .join(""),
                                            style: "default",
                                            size: "half",
                                          },
                                          {
                                            type: "map",
                                            longitude: DETAIL.longitude,
                                            latitude: DETAIL.latitude,
                                            style: "default",
                                            size: "half",
                                          },
                                        ].concat([...DETAIL.blocks])}
                                        entry={DETAIL}
                                      />
                                    </div>
                                  </div>
                                );
                              }}
                            </Data>
                          </div>

                          {/* Results Map */}
                          <div className="Site-results-map">
                            <Breakpoints.Default>
                              <Data require={["IS_HOME", "filters", "entries"]}>
                                {({
                                  IS_HOME,
                                  ENTRY,
                                  HOME,
                                  DETAIL,
                                  FILTER,
                                  FILTERING,
                                  SEARCH,
                                  filters,
                                  entries,
                                }) => {
                                  // No filter, use the first from the filtes nav
                                  if (!FILTERING)
                                    FILTER = entries.find(
                                      (e) => e.id == filters[0].id
                                    );

                                  return (
                                    <Filter
                                      search={
                                        !SEARCH
                                          ? null
                                          : {
                                              value: SEARCH,
                                              fields: [
                                                "title",
                                                "text",
                                                "location",
                                                "address",
                                                "metaKeywords",
                                                "metaTitle",
                                                "metaDescription",
                                              ],
                                            }
                                      }
                                      filters={{ type: "locations" }}
                                      data={
                                        !FILTER
                                          ? entries
                                          : FILTER.related.map((id) =>
                                              entries.find((e) => e.id == id)
                                            )
                                      }
                                      results={(items) => {
                                        if (!items.length) return null;

                                        let bounds =
                                          FILTER.bounds && FILTER.bounds.length
                                            ? FILTER.bounds.map((id) =>
                                                entries.find((e) => e.id == id)
                                              )
                                            : items;
                                        let BOUNDSMARKERS = bounds.map((b) => ({
                                          lat: b.latitude,
                                          lng: b.longitude,
                                        }));

                                        return (
                                          <Map
                                            key={JSON.stringify(items)}
                                            styles={mapTint(
                                              this.state.palette.bg
                                            )}
                                            fitBounds
                                            boundsmarkers={
                                              SEARCH || FILTER
                                                ? BOUNDSMARKERS
                                                : []
                                            }
                                            markers={
                                              SEARCH || FILTER
                                                ? items.map((i) => ({
                                                    lat: i.latitude,
                                                    lng: i.longitude,
                                                    onMouseOut: () =>
                                                      this.setState({
                                                        hover: 0,
                                                      }),
                                                    onMouseOver: () =>
                                                      this.activate(i.id),
                                                    content: (
                                                      <div
                                                        className={
                                                          "Map-card " +
                                                          (this.state.hover ==
                                                          i.id
                                                            ? "is-active"
                                                            : "is-inactive")
                                                        }
                                                      >
                                                        <div
                                                          className={
                                                            "Map-card-item"
                                                          }
                                                        >
                                                          <div
                                                            className={
                                                              "Item Item--map"
                                                            }
                                                            key={i.id}
                                                          >
                                                            <div className="Item-content">
                                                              <div className="Item-head">
                                                                <Content>
                                                                  <h1>
                                                                    {i.title}
                                                                  </h1>
                                                                  <h2>
                                                                    {i.location}
                                                                  </h2>
                                                                </Content>
                                                              </div>
                                                              <div
                                                                className="Item-media"
                                                                key={
                                                                  i.thumbnail
                                                                }
                                                              >
                                                                <Asset
                                                                  id={
                                                                    i.thumbnail
                                                                  }
                                                                  render={(
                                                                    asset
                                                                  ) => {
                                                                    return (
                                                                      <Image
                                                                        src={
                                                                          asset
                                                                            .sizes
                                                                            .small
                                                                        }
                                                                        fluid={
                                                                          false
                                                                        }
                                                                        ratio={
                                                                          6 / 4
                                                                        }
                                                                        lazy={
                                                                          true
                                                                        }
                                                                      />
                                                                    );
                                                                  }}
                                                                />
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </div>
                                                        <div
                                                          className="Map-card-dot"
                                                          style={{
                                                            background:
                                                              this.state.palette
                                                                .accent,
                                                          }}
                                                        />
                                                      </div>
                                                    ),
                                                    onClick: () => {
                                                      this.props.history.push(
                                                        window.location
                                                          .pathname +
                                                          "#/filter/" +
                                                          FILTER.slug +
                                                          "/details/" +
                                                          i.slug +
                                                          "/"
                                                      );
                                                    },
                                                  }))
                                                : []
                                            }
                                          ></Map>
                                        );
                                      }}
                                    ></Filter>
                                  );
                                }}
                              </Data>
                            </Breakpoints.Default>
                          </div>

                          {/*
                            <div className="Site-filters" >
                                <Data require={['filters']}>
                                  {({navigation,filters})=>{
                                    return (
                                        <ul className="List List--filters">
                                          <li><Content><h3><span className="Site-filters-heading" style={{background:this.state.palette.contrast,color:this.state.palette.bg}}>See map of:</span></h3></Content></li>
                                          {filters.map(((item,ix)=>(
                                              <li key={'filter_'+ix}><span style={{background:this.state.palette.bg}}><Link navlink autotarget matchUri={'#/filter/'+item.slug+'/'} to={'#/filter/'+item.slug+'/'}>{item.title}</Link></span></li>
                                          )))}
                                        </ul>
                                    )}}
                                  </Data>
                              </div>
                            */}

                          <Link
                            to={"#/filter/" + filters[0].slug + "/"}
                            className="Site-browse"
                          >
                            <Icon glyph type={"guide"} />
                          </Link>

                          <SiteModifier toggle="menu">
                            <div className="Site-burger" />
                          </SiteModifier>

                          <div className="Site-menu">
                            <Data require={["navigation"]}>
                              {({ navigation }) => {
                                return (
                                  <SiteModifier
                                    demodifier="menu"
                                    delay={250}
                                    forceDelay={true}
                                  >
                                    <ul className="List List--menu">
                                      {navigation.map((item, ix) => (
                                        <li key={"nav_" + ix}>
                                          <Link
                                            navlink
                                            autotarget
                                            matchUri={item.uri}
                                            to={item.uri}
                                          >
                                            {item.title}
                                          </Link>
                                        </li>
                                      ))}
                                    </ul>
                                  </SiteModifier>
                                );
                              }}
                            </Data>
                          </div>

                          <SiteModifier demodifier="menu">
                            <div className="Site-menu-close" />
                          </SiteModifier>

                          <div className="Site-layout">
                            <Project.Router />
                          </div>

                          <Data require={["ENTRY"]}>
                            {({ ENTRY, DETAIL, FILTERING, FILTER, SEARCH }) => {
                              let META = ENTRY;
                              if (FILTERING && FILTER) META = FILTER;
                              if (DETAILS && DETAIL) META = DETAIL;
                              if (SEARCH)
                                META = {
                                  ...META,
                                  metaTitle: "Search results for " + SEARCH,
                                };
                              if (META) {
                                return (
                                  <Helmet>
                                    <meta
                                      property="og:description"
                                      content={META.metaDescription}
                                    />
                                    <meta
                                      name="description"
                                      content={META.metaDescription}
                                    />
                                    <meta
                                      name="twitter:description"
                                      content={META.metaDescription}
                                    />
                                    <meta
                                      name="keywords"
                                      content={META.metaKeywords}
                                    />
                                    <title>
                                      Acme |{" "}
                                      {META.metaTitle
                                        ? META.metaTitle
                                        : META.title}
                                    </title>
                                    {(() => {
                                      if (
                                        META.hasOwnProperty("schema") &&
                                        META.schema
                                      ) {
                                        return (
                                          <script type="application/ld+json">
                                            {JSON.stringify(META.schema)}
                                          </script>
                                        );
                                      } else {
                                        return null;
                                      }
                                    })()}
                                  </Helmet>
                                );
                              } else {
                                return null;
                              }
                            }}
                          </Data>
                        </FadeIn>
                      );
                    }}
                  </Data>
                );
              }}
            />
          </div>
        </FadeIn>
      </Site>
    );
  }
}

export default withRouter(withStore(Index));
